@tailwind base;
@tailwind components;
@tailwind utilities;
/**
* Add css variables here as well for light mode in addition to tailwind.config to avoid FOUC
*/
:root {
  
  /* background */

  --cal-bg-emphasis: #e1f5ff;
  /* --cal-bg-emphasis: #e5e7eb; */
  --cal-bg: white;
  --cal-bg-subtle: #f3f4f6;
  --cal-bg-muted: #f9fafb;
  --cal-bg-inverted: #111827;

  /* background -> components*/
  --cal-bg-info: #dee9fc;
  --cal-bg-success: #e2fbe8;
  --cal-bg-attention: #fceed8;
  --cal-bg-error: #f9e3e2;
  --cal-bg-dark-error: #752522;

  /* Borders */
  --cal-border-emphasis: #9ca3af;
  --cal-border: #d1d5db;
  --cal-border-subtle: #e5e7eb;
  --cal-border-booker: #e5e7eb;
  --cal-border-muted: #f3f4f6;
  --cal-border-error: #aa2e26;

  /* Content/Text */
  --cal-text-emphasis: #03045e;
  /* --cal-text-emphasis: #111827; */
  --cal-text: #374151;
  --cal-text-subtle: #6b7280;
  --cal-text-muted: #9ca3af;
  --cal-text-inverted: white;

  /* Content/Text -> components */
  --cal-text-info: #253985;
  --cal-text-success: #285231;
  --cal-text-attention: #73321b;
  --cal-text-error: #752522;

  /* Brand shinanigans
      -> These will be computed for the users theme at runtime.
    */
  --cal-brand: #03045e;
  /* --cal-brand-emphasis: #101010; */
   --cal-brand-emphasis: #03045e;
  --cal-brand-text: white;
  /* --cal-brand: #03045E;
  --cal-brand-emphasis: red;
  --cal-brand-text: pink; */
}
.dark {
  /* background */

  --cal-bg-emphasis: #2b2b2b;
  --cal-bg: #101010;  
  --cal-bg-subtle: #2b2b2b;
  --cal-bg-muted: #1c1c1c;
  --cal-bg-inverted: #f3f4f6;

  /* background -> components*/
  --cal-bg-info: #263fa9;
  --cal-bg-success: #306339;
  --cal-bg-attention: #8e3b1f;
  --cal-bg-error: #8c2822;
  --cal-bg-dark-error: #752522;

  /* Borders */
  --cal-border-emphasis: #575757;
  --cal-border: #444444;
  --cal-border-subtle: #2b2b2b;
  --cal-border-booker: #2b2b2b;
  --cal-border-muted: #1c1c1c;
  --cal-border-error: #aa2e26;

  /* Content/Text */
  --cal-text-emphasis: #f3f4f6;
  --cal-text: #d6d6d6;
  --cal-text-subtle: #a5a5a5;
  --cal-text-muted: #575757;
  --cal-text-inverted: #101010;

  /* Content/Text -> components */
  --cal-text-info: #dee9fc;
  --cal-text-success: #e2fbe8;
  --cal-text-attention: #fceed8;
  --cal-text-error: #f9e3e2;

  /* Brand shenanigans
      -> These will be computed for the users theme at runtime.
    */
  --cal-brand: white;
  --cal-brand-emphasis: #e1e1e1;
  --cal-brand-text: black;
}

@layer base {
  * {
    @apply border-default 
  }
}

::-moz-selection {
  color: var(--cal-brand-text);
  background:#03045e;
}

::selection {
  color: var(--cal-brand-text);
  background: #03045e;
}

html {
  scrollbar-gutter: stable;
}

body  {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

/*
  Desktop App specific CSS
    https://docs.todesktop.com/
*/

/* disable user selection on buttons, links and images on desktop app */
html.todesktop button,
html.todesktop a,
html.todesktop img,
html.todesktop header {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default !important;
}

html.todesktop,
html.todesktop div {
  cursor: default !important;
}

/* make header draggable on desktop app */
html.todesktop header {
  -webkit-app-region: drag;
}
html.todesktop header button {
  -webkit-app-region: no-drag;
}

html.todesktop .logo {
  display: none;
}

.desktop-only {
  display: none;
}

html.todesktop .desktop-only {
  display: block;
}

html.todesktop .desktop-hidden {
  display: none;
}

html.todesktop header {
  margin-top: -14px;
}

html.todesktop header nav {
  margin-top: 8px;
}

html.todesktop aside {
  margin: 0px -6px;
}

html.todesktop-platform-darwin .desktop-transparent {
  background: transparent !important;
  border: none !important;
}

html.todesktop-platform-darwin.dark main.bg-default {
  background: rgba(0, 0, 0, 0.6) !important;
}

html.todesktop-platform-darwin.light main.bg-default {
  background: rgba(255, 255, 255, 0.8) !important;
}

/*
html.todesktop aside a {
  height: 28px;
  padding: 0px 8px;
  font-size: 12px;
  color: #383438 !important
}

html.todesktop nav a:hover{
  background-color: inherit !important
}

html.todesktop nav a[aria-current="page"]{
  background: rgba(0, 0, 0, 0.1) !important;
}

html.todesktop nav a svg{
  color: #0272F7 !important
} */

/*
  Adds Utility to hide scrollbar to tailwind
    https://github.com/tailwindlabs/tailwindcss/discussions/2394
    https://github.com/tailwindlabs/tailwindcss/pull/5732
*/
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Noto Sans", sans-serif !important;
}
div, p, span, a, button, input, textarea, select, option, optgroup, label {
  font-family: "Mulish", sans-serif !important;
  font-weight: 600 !important;
}

/*
 * Override the default tailwindcss-forms styling (default is: 'colors.blue.600')
 * @see: https://github.com/tailwindlabs/tailwindcss-forms/issues/14#issuecomment-1005376006
 */
[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="checkbox"]:focus,
[type="radio"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: #03045e;
  border-color: #03045e;
}

@layer components {
  .scroll-bar {
    @apply scrollbar-thin scrollbar-thumb-rounded-md dark:scrollbar-thumb-darkgray-300 scrollbar-thumb-gray-300 scrollbar-track-transparent;
  }
}

/* TODO: avoid global specific css */
/* button[role="switch"][data-state="checked"] span {
  transform: translateX(16px);
} */

@layer components {
  /* slider */
  .slider {
    @apply relative flex h-4 w-40 select-none items-center;
  }

  .slider > .slider-track {
    @apply relative h-1 flex-grow rounded-md bg-gray-400;
  }

  .slider .slider-range {
    @apply absolute h-full rounded-full bg-gray-700;
  }

  .slider .slider-thumb {
    @apply block h-3 w-3 cursor-pointer rounded-full bg-gray-700 transition-all;
  }

  .slider .slider-thumb:hover {
    @apply bg-gray-600;
  }

  .slider .slider-thumb:focus {
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.2);
  }
}

/* hide chat bubble on mobile */
@media only screen and (max-width: 768px) {
  /* Intercom FAB*/
  #launcher {
    display: none !important;
  }

  /* Zendesk FAB*/
  div[role="presentation"] > iframe {
    display: none !important;
  }

  /* Helpscout FAB*/
  .BeaconFabButtonFrame {
    margin-left: -30px;
    left: 50%;
    bottom: 28px !important;
    z-index: 1058 !important;
  }
}

/* TODO: implement styling for react-multi-email */

/* !important to override react-dates */
.DateRangePickerInput__withBorder {
  border: 0 !important;
}
.DateInput_input {
  border: 1px solid #d1d5db !important;
  border-radius: 2px !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  color: #000;
  padding: 11px ​11px 9px !important;
  line-height: 16px !important;
}

.DateInput_input__focused {
  border: 2px solid #000 !important;
  border-radius: 2px !important;
  box-shadow: none !important;
  padding: 10px ​10px 9px !important;
}

.DateRangePickerInput_arrow {
  padding: 0px 10px;
}

.loader {
  display: block;
  width: 30px;
  height: 30px;
  margin: 60px auto;
  position: relative;
  border-width: 4px;
  border-style: solid;
  animation: loader 2s infinite ease;
}

.loader-inner {
  vertical-align: top;
  display: inline-block;
  width: 100%;
  animation: loader-inner 2s infinite ease-in;
}

.no-ring-inset {
  --tw-ring-inset: unset;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(180deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes loader-inner {
  0% {
    height: 0%;
  }

  25% {
    height: 0%;
  }

  50% {
    height: 100%;
  }

  75% {
    height: 100%;
  }

  100% {
    height: 0%;
  }
}

.text-inverted-important {
  color: white !important;
}

@layer utilities {
  .transition-max-width {
    -webkit-transition-property: max-width;
    transition-property: max-width;
  }
}

#timeZone input:focus {
  box-shadow: none;
}

/* react-date-picker forces a border upon us, cast it away */
.react-date-picker__wrapper {
  border: none !important;
}

.react-date-picker__inputGroup__input {
  padding-top: 0;
  padding-bottom: 0;
}

/* animations */
.slideInBottom {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInBottom;
}

@keyframes slideInBottom {
  from {
    opacity: 0;
    transform: translateY(30%);
    pointer-events: none;
  }
  to {
    opacity: 1;
    pointer-events: auto;
  }
}

/* animations */
.slideInTop {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInTop;
}

@keyframes slideInTop {
  from {
    opacity: 0;
    transform: translateY(-20%);
    pointer-events: none;
  }
  to {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0%);
  }
}

.fadeIn {
  animation-duration: 0.3s;
  /* animation-fill-mode: both; */
  animation-name: fadeIn;
  animation-timing-function: ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/**
 * Makes sure h-screen works on mobile Safari. By default h-screen
 * does not take into account the height of the address bar, causing
 * weird behaviour when scrolling — sometimes the height will be correct
 * and sometimes it won't, depending on whether the address bar is
 * in 'collapsed' state or not.
 * @see: https://benborgers.com/posts/tailwind-h-screen
 */
@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.react-tel-input .country-list .country:hover,
.react-tel-input .country-list .country.highlight {
  @apply !bg-emphasis;
}

.react-tel-input .flag-dropdown .selected-flag,
.react-tel-input .flag-dropdown.open .selected-flag {
  @apply !bg-default;
}

.react-tel-input .flag-dropdown {
  @apply !border-r-default left-0.5 !border-y-0 !border-l-0;
}

/* .bg-brand-default{
  background-color: #03045e !important;
} */


/* scroll bar */

/* width */
::-webkit-scrollbar {
  width: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #03045E !important;
  
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}